import React from "react";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import WorksHeader from "components/Works-header/works-header";
import WorksStyle2 from "components/Works-style2/works-style2";
import { GetProjectsData } from "components/ApiServices/GetProjectsData";
import { useState } from "react";
import SEO from "components/seo/seo.jsx";
import LoadingScreen from "components/Loading-Screen";

const WorksDark = (props) => {
  const ProjectsData = GetProjectsData();
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [fixedHeader, MainContent, navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <WorksHeader sliderRef={fixedHeader} />
      <div ref={MainContent} className="main-content">
        <WorksStyle2
          grid={3}
          filterPosition="center"
          projectData={ProjectsData}
          propData={props}
          hideTitle
        />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO
        canonical={liveUrl}
        title="Our Work - Case Studies of Delivered Digital Solutions | Evolvan"
        description="Explore how Evolvan, A digital experience agency, is helping enterprises and startups to deliver a seamless customer experience across all the channels."
      />
    </>
  );
};

export default WorksDark;
