import React from "react";
import fadeWhenScroll from "common/fadeWhenScroll";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";

const BlogsHeader = ({ sliderRef }) => {
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .capt .parlx"));
  }, []);

  return (
    <header
      ref={sliderRef}
      className="works-header fixed-slider hfixd valign sub-bg"
    >
      <ParticalsOverlay />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 static">
            <div className="capt mt-50">
              <div className="parlx text-center">
                <h1 className="color-font">Our Works</h1>
                <p>
                  Partner with one of the rapidly growing digital Consultancy in
                  India
                </p>
                <p>
                  We specialize in designing and developing web and mobile
                  applications for global clients with a strong emphasis on
                  delivering exceptional user experience.
                </p>
                <a href="/contact" className="butn bord curve mt-30 banner-cta">
                  Let's connect
                </a>
              </div>

              <div className="bactxt custom-font valign">
                <span className="full-width">Work</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BlogsHeader;
